import { Box } from '@mui/material'
import React from 'react'
import {
    StyledButton,
    StyledButtonContainer,
    StyledImg,
    StyledProjectContainer,
    StyledProjects,
    StyledText,
} from './styled'
import { StyledContainer, StyledText3 } from '../Profile/styled'
import SubHeadings from '../SubHeadings'
import { useJsonValues } from '../../hooks'
import { Link } from 'react-router-dom'
// import { StyledText } from '../About/styled'

const Projects = () => {
    const { data } = useJsonValues()

    const { text, title, projects = [] } = data.project

    return (
        <StyledContainer id="projects">
            <Box>
                <SubHeadings text={text} title={title} />
                <StyledProjectContainer>
                    {projects.map(
                        (
                            {
                                image,
                                projectTitle,
                                desc,
                                github,
                                githubLink,
                                liveDemo,
                                liveDemoLink,
                            },
                            index
                        ) => (
                            <StyledProjects key={index}>
                                <Box>
                                    <StyledImg src={image} alt="Project One" />
                                </Box>
                                <StyledText3
                                    sx={{
                                        color: '#000 !important',
                                        m: '1rem 2rem',
                                        textAlign: 'left',
                                    }}
                                >
                                    {projectTitle}
                                </StyledText3>
                                <StyledText>{desc}</StyledText>
                                <StyledButtonContainer>
                                    <Link to={githubLink}>
                                        <StyledButton variant="outlined">
                                            {github}
                                        </StyledButton>
                                    </Link>

                                    <Link to={liveDemoLink}>
                                        <StyledButton variant="outlined">
                                            {liveDemo}
                                        </StyledButton>
                                    </Link>
                                </StyledButtonContainer>
                            </StyledProjects>
                        )
                    )}
                </StyledProjectContainer>
            </Box>
        </StyledContainer>
    )
}

export default Projects
