import { Box, Button, Container, styled, Typography } from "@mui/material";

export const StyledImg = styled('img')(() => ({
    borderRadius: '2rem',
    width: '100%',
    height: '100%',
    marginBottom: '1rem'
}))

export const StyledProjectContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',

    [theme.breakpoints.up('xl')]: {
        margin: '0 10rem',
        gap: '3rem',
    },

    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },

    [theme.breakpoints.only('sm')]: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(2, 1fr)',
        margin: '1.5rem',
        gap: '1rem'
    },
}))

export const StyledProjects = styled(Box)(({ theme }) => ({
    border: '0.1rem solid',
    borderColor: theme.palette.textColor.borderColor,
    padding: '1rem',
    borderRadius: '2rem',
    backgroundColor: 'rgb(250, 250, 250)',
    width: '100%',


    [theme.breakpoints.up('xl')]: {
        marginTop: '3rem',
        padding: '2rem',
        paddingTop: '3rem',
    },

    [theme.breakpoints.down('md')]: {
        marginTop: '2rem',
        width: 'auto'
    },
}))

export const StyledText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    color: theme.palette.textColor.paragraph,
    wordWrap: 'break-word',
    hyphens: 'auto',
    textAlign: 'justify',
    margin: '1rem',

    [theme.breakpoints.up('xl')]: {
        fontSize: `calc(1.2rem + (100vw - 1536px) * 0.01)`,
    },

    [theme.breakpoints.down('md')]: {
        margin: '1rem 0'
    },
}))

export const StyledButtonContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
}))

export const StyledButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.textColor.borderColor,
    color: theme.palette.textColor.main,
    borderRadius: '2rem',
    width: '8rem',
    padding: '.8rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    transition: 'all 300ms ease',

    '&:hover': {
        backgroundColor: theme.palette.textColor.buttonColor,
        color: theme.palette.textColor.whiteColor,
        borderColor: theme.palette.textColor.buttonColor
    },

    [theme.breakpoints.up('xl')]: {
        marginTop: '1rem',
        padding: '1rem',
        width: `calc(10rem + (100vw - 1536px) * 0.1)`,
        fontSize: `calc(1.2rem + (100vw - 1920px)* 0.01)`,
        borderRadius: '4rem',

    },
}))